import Papa from 'papaparse'
import { useI18n } from 'vue-i18n'

export function useCsvExport(fileName, formatter, fields) {
  const i18n = useI18n()
  const downloadCsvHandler = (jsonData) => {
    const cleanedData = formatter(jsonData, i18n).map((elem) => {
      Object.keys(fields).forEach(key => {
        elem[fields[key]] = elem[key]
        delete elem[key]
      })
      return elem
    })
    var blob = new Blob([Papa.unparse(cleanedData)], { type: 'text/csv;charset=utf-8;' })
    var link = document.createElement('a')
    var url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return {
    downloadCsvHandler,
  }
}
