import { useHumanDate } from '@/application/composables/humanDate.js'
import { getLocalizedOrValue } from '@/application/utils/localization'

export const formatterCSVSentMessages = (items, i18n) => {
  const { humanDateTime } = useHumanDate()

  return items.map(item => {
    return {
      recipient: item.recipient,
      text: item.text,
      status: i18n.t(`messagesStatus.${item.status}`),
      sandbox: item.sandbox ? i18n.t('yes') : i18n.t('no'),
      date: humanDateTime(item.create_date, i18n.locale),
    }
  })
}

export const formatterCSVInboundMessages = (items, i18n) => {
  const { humanDateTime } = useHumanDate()

  return items.map(item => {
    return {
      recipient: item.recipient,
      text: item.text,
      type: item.type === 'text' ? i18n.t('text') : i18n.t('reaction'),
      sandbox: item.sandbox ? i18n.t('yes') : i18n.t('no'),
      date: humanDateTime(item.create_date, i18n.locale),
    }
  })
}

export const formatterCSVScheduledCampaigns = (items, i18n) => {
  const { humanDateTime } = useHumanDate()

  return items.map(item => {
    return {
      send_from_date: humanDateTime(item.send_from_date, i18n.locale),
      status: item.status,
      recipients: item.recipients,
      sandbox: item.sandbox ? i18n.t('yes') : i18n.t('no'),
      send_from_time: `${item.send_from_time} - ${item.send_to_time}`,
    }
  })
}

export const formatterCSVScheduledMessages = (items, i18n) => {
  const { humanDateTime } = useHumanDate()

  return items.map(item => {
    return {
      recipients: item.recipients,
      text: item.text,
      status: i18n.t(`scheduledCampaignStatus.${item.status}`),
      sandbox: item.sandbox ? i18n.t('yes') : i18n.t('no'),
      date: humanDateTime(item.create_date, i18n.locale),
    }
  })
}

export const formatterCSVInvoices = (items, i18n) => {
  const { humanDate } = useHumanDate()

  return items.map(item => {
    return {
      invoice_no: item.invoice_no,
      localized_total_amount: item.localized_total_amount,
      date: humanDate(item.date, i18n.locale, { year: 'numeric', month: 'short', day: 'numeric' }),
      status: i18n.t(`forms.billing.payment_statuses.${item.status}`),
      due_date: item.status === 'unpaid' && item.due_date ? humanDate(item.due_date, i18n.locale, { year: 'numeric', month: 'short', day: 'numeric' }) : '-',
      paid_date: item.status === 'paid' ? humanDate(item.paid_date, i18n.locale, { year: 'numeric', month: 'short', day: 'numeric' }) : '-',
    }
  })
}

export const formatterCSVBillingHistory = (items, i18n) => {
  const { humanDateTime } = useHumanDate()

  return items.map(item => {
    return {
      create_date: item.create_date ? humanDateTime(item.create_date, i18n.locale) : '',
      currency: item.currency,
      price: item.price,
      payment_tax: item.payment_tax,
      gross_price: item.gross_price,
      payment_method: getLocalizedOrValue(i18n, `paymentMethod.${item.payment_method}`, item.payment_method),
      coupon: item.coupon ? item.coupon : '-',
    }
  })
}

export const formatterCSVBillingBalanceHistory = (items, i18n) => {
  const { humanDate, humanDateTime } = useHumanDate()

  return items.map(item => {
    return {
      messages: item.messages,
      package_messages: item.package_messages,
      sandbox_messages: item.sandbox_messages,
      package_sandbox_messages: item.package_sandbox_messages,
      create_date: humanDateTime(item.create_date, i18n.locale),
      expire_date: humanDate(item.expire_date, i18n.locale),
    }
  })
}

export const formattedCSVWebhookHistory = (items, i18n) => {
  const { humanDateTime } = useHumanDate()
  return items.map(item => {
    return {
      webhook_id: item.webhook_id,
      message_id: item.message_id,
      status: item.status,
      attempt: item.attempt,
      max_attempts: item.max_attempts,
      event_type: item.event_type,
      last_update: humanDateTime(item.update_date, i18n.locale),
      date: humanDateTime(item.create_date, i18n.locale),
    }
  })
}

export const formattedCSVLookupBulkHistory = (items, i18n) => {
  const { humanDate } = useHumanDate()
  return items.map(item => {
    return {
      id: item.id,
      count: item.count,
      completed: Boolean(item.completed),
      date: humanDate(item.create_date, i18n.locale),
    }
  })
}

export const formattedCSVLookupHistory = (items, i18n) => {
  const { humanDate, humanDateTime } = useHumanDate()
  return items.map(item => {
    const result = item.result_v1
    const apple_services = result && result.apple_services
    return {
      id: item.id,
      recipient: item.contact,
      status: item.status,
      date: humanDate(item.create_date, i18n.locale),
      facetime: (apple_services && apple_services.facetime.status) || '-',
      facetime_last_check: (apple_services && apple_services.facetime.date) ? humanDateTime(apple_services.facetime.date, i18n.locale) : '-',
      facetime_audio: (apple_services && apple_services.facetime.links && apple_services.facetime.links.facetime_audio) || '',
      imessage: (apple_services && apple_services.imessage.status) || '-',
      imessage_last_check: (apple_services && apple_services.imessage.date) ? humanDateTime(apple_services.imessage.date, i18n.locale) : '-',
      imessage_sms: (apple_services && apple_services.imessage.links && apple_services.imessage.links.sms) || '-',
      carrier: (result && result.carrier && result.carrier.carrier) || '-',
      carrier_number_type: (result && result.carrier && result.carrier.number_type) || '-',
      country_name: (result && result.country && result.country.name) || '-',
      country_flag: (result && result.country && result.country.flag) || '-',
      country_iso2: (result && result.country && result.country.iso2) || '-',
      country_iso3: (result && result.country && result.country.iso3) || '-',
      country_numeric: (result && result.country && result.country.numeric) || '-',
      formats_e164: (result && result.format && result.format.e164) || '-',
      formats_international: (result && result.format && result.format.international) || '-',
      formats_national: (result && result.format && result.format.national) || '-',
      formats_rfc3966: (result && result.format && result.format.rfc3966) || '-',
      currencies: result && result.currencies && result.currencies.join(', '),
      timezones: result && result.time_zones && result.time_zones.join(', '),
    }
  })
}

export const formatterCSVActivePurchases = (items, i18n) => {
  const { humanDate } = useHumanDate()
  const formatSenderNames = (senderNames) => {
    const senderNamesList = []
    senderNames.forEach((item) => senderNamesList.push(item.name))
    return senderNamesList.join('; ')
  }
  return items.map(item => {
    return {
      price: item.localized_price,
      quantity: item.quantity,
      status: item.status,
      messages: (item.message_package && item.message_package.messages) || '-',
      term: (item.message_package && item.message_package.term && i18n.t(`forms.buyPackages.terms.${item.message_package.term}`)) || '-',
      senderNames: (item.sender_names && formatSenderNames(item.sender_names)) || '-',
      paymentMethod: (item.payment_info && item.payment_info.payment_method) || '-',
      lastFourDigits: (item.payment_info && item.payment_info.last_four_digits) || '-',
      expiryDate: (item.payment_info && item.payment_info.expiry_date && humanDate(item.payment_info.expiry_date, i18n.locale)) || '-',
      nextBillDate: (item.next_bill_date && humanDate(item.next_bill_date, i18n.locale)) || '-',
    }
  })
}
